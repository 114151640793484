import { Box, CheckboxGroup, HStack, Stack, Text } from '@chakra-ui/react';
import { AccordionItem, AccordionItemContent, AccordionItemTrigger, AccordionRoot } from 'components/ui/accordion';
import { Button } from 'components/ui/button';
import { Checkbox } from 'components/ui/checkbox';
import DatePicker from 'components/ui/date-picker';
import { NativeSelectField, NativeSelectRoot } from 'components/ui/native-select';
import { useLocationData } from 'hooks/useLocationData';
import { useStateOptions } from 'hooks/useStateOptions';
import { UseTableFiltersType } from 'hooks/useTableFilters';
import { useEffect, useState } from 'react';

type ExemptionFilterProps = {
  onClose: () => void;
  tableFilters: UseTableFiltersType;
};
export const ExemptionFilter = ({
  onClose,
  tableFilters: { country_code, jurisdiction, start_date, end_date, status__in, setFilters },
}: ExemptionFilterProps) => {
  const { countries } = useLocationData();
  const { stateOptions, fetchStateOptions } = useStateOptions({ formatter: 'ca_exemption' });
  const [accordionIndex, setAccordionIndex] = useState<string[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string | undefined>(country_code);
  const [selectedState, setSelectedState] = useState<string | undefined>(jurisdiction);

  const [filter, setFilter] = useState<{
    country_code?: string;
    jurisdiction?: string;
    status__in?: string[];
    start_date?: string;
    end_date?: string;
  }>({ country_code, jurisdiction, status__in: status__in?.split(','), start_date, end_date });

  useEffect(() => {
    if (selectedCountry) {
      fetchStateOptions(selectedCountry);
    }
  }, [selectedCountry, fetchStateOptions]);

  const handleSave = () => {
    setFilters({
      country_code: filter.country_code,
      jurisdiction: filter.jurisdiction,
      status__in: filter.status__in,
      start_date: filter.start_date,
      end_date: filter.end_date,
    });
    onClose();
  };

  const handleClear = () => {
    setFilter({});
    setFilters({
      country_code: undefined,
      jurisdiction: undefined,
      status__in: undefined,
      start_date: undefined,
      end_date: undefined,
    });
    onClose();
  };

  const nofilter = !filter;

  return (
    <Stack>
      <HStack justifyContent={'space-between'}>
        <Text fontSize={'18px'} fontWeight={500}>
          Filter by
        </Text>
        {filter && (
          <Text fontSize={'12px'} color={'#4285F4'} cursor={'pointer'} onClick={handleClear}>
            Clear All Filters
          </Text>
        )}
      </HStack>
      <AccordionRoot
        variant="plain"
        multiple
        value={accordionIndex}
        onValueChange={({ value }) => setAccordionIndex(value)}
      >
        <AccordionItem value="country">
          <AccordionItemTrigger>
            <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
              Country
            </Box>
          </AccordionItemTrigger>
          <AccordionItemContent mb={2}>
            <NativeSelectRoot>
              <NativeSelectField
                value={selectedCountry}
                onChange={e => {
                  setSelectedCountry(e.target.value);
                  setFilter({ ...filter, country_code: e.target.value });
                }}
                placeholder="Select"
              >
                {countries.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </NativeSelectField>
            </NativeSelectRoot>
          </AccordionItemContent>
        </AccordionItem>
        <AccordionItem value="jurisdiction">
          <AccordionItemTrigger>
            <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
              Jurisdiction
            </Box>
          </AccordionItemTrigger>
          <AccordionItemContent mb={2}>
            <NativeSelectRoot>
              <NativeSelectField
                value={selectedState}
                onChange={e => {
                  setSelectedState(e.target.value);
                  setFilter({ ...filter, jurisdiction: e.target.value });
                }}
                placeholder="Select"
              >
                {stateOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </NativeSelectField>
            </NativeSelectRoot>
          </AccordionItemContent>
        </AccordionItem>
        <AccordionItem value="date">
          <AccordionItemTrigger>
            <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
              Date
            </Box>
          </AccordionItemTrigger>
          <AccordionItemContent mb={2}>
            <Box mb={4}>
              <Text fontWeight={'medium'} fontSize={'sm'} mb={2}>
                Start Date
              </Text>
              <DatePicker
                placeholderText="Select"
                selected={filter.start_date}
                onChange={date => {
                  setFilter({
                    ...filter,
                    start_date: date,
                  });
                }}
              />
            </Box>
            <Box width={'100%'}>
              <Text fontWeight={'medium'} fontSize={'sm'} mb={2}>
                End Date
              </Text>
              <DatePicker
                placeholderText="Select"
                selected={filter.end_date}
                onChange={date => {
                  setFilter({
                    ...filter,
                    end_date: date,
                  });
                }}
              />
            </Box>
          </AccordionItemContent>
        </AccordionItem>
        <Stack>
          <AccordionItem value="status">
            <AccordionItemTrigger>
              <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
                Status
              </Box>
            </AccordionItemTrigger>
            <AccordionItemContent mb={2}>
              <Stack>
                <CheckboxGroup
                  value={filter.status__in?.filter(Boolean)}
                  onValueChange={values => {
                    setFilter({
                      ...filter,
                      status__in: values,
                    });
                  }}
                >
                  <Checkbox value="ACTIVE">Active</Checkbox>
                  <Checkbox value="INACTIVE">Inactive</Checkbox>
                  <Checkbox value="EXPIRED">Expired</Checkbox>
                </CheckboxGroup>
              </Stack>
            </AccordionItemContent>
          </AccordionItem>
        </Stack>
      </AccordionRoot>
      <HStack direction="row" justifyContent={'space-around'} gap="18px">
        <Button variant="outline" color="secondary" onClick={onClose} width={'132px'} height={'32px'}>
          Cancel
        </Button>
        <Button
          variant="solid"
          color="primary"
          onClick={handleSave}
          width={'132px'}
          height={'32px'}
          disabled={nofilter}
        >
          Apply
        </Button>
      </HStack>
    </Stack>
  );
};
