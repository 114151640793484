import { CountryCodeEnum } from '_api-client';
import { createListCollection, Flex, HStack, IconButton, Input, Stack, Text } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createExemption, uploadResellerCertificate } from 'apis/exemption-apis';
import { EXEMPTIONS_STATE_KEY } from 'apis/exemptions';
import { RemoveIcon } from 'components/icons';
import { Button } from 'components/ui/button';
import DatePicker from 'components/ui/date-picker';
import { DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle } from 'components/ui/dialog';
import { Field } from 'components/ui/field';
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from 'components/ui/select';
import { Switch } from 'components/ui/switch';
import { toaster } from 'components/ui/toaster';
import { addYears, parseISO } from 'date-fns';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useLocationData } from 'hooks/useLocationData';
import { useOrg } from 'hooks/useOrg';
import { useStateOptions } from 'hooks/useStateOptions';
import { useEffect, useMemo, useRef, useState } from 'react';
import { getFormattedDate } from 'utils/dates';
import { date, object, ref, string } from 'yup';

import { SearchCustomerInput } from './customer-search';

const validationSchema = object().shape({
  country_code: string().required('Country is required'),
  start_date: date().required('Start date is required'),
  end_date: date().min(ref('start_date'), "End date can't be before start date").required('End date is required'),
  jurisdiction: string().required('Jurisdiction is required'),
  FEIN: string().required('FEIN/Tax ID is required'),
  sales_tax_id: string().required('Sales Tax Id is required'),
  customer_id: string().required('Customer is required'),
});

type ImportExemptionModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ImportExemptionModal = ({ isOpen, onClose }: ImportExemptionModalProps) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const { orgId } = useOrg();
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();
  const queryClient = useQueryClient();
  const [fileName, setFileName] = useState('');
  const { countries } = useLocationData();
  const { stateOptions, fetchStateOptions } = useStateOptions({ formatter: 'ca_exemption' });

  const [country, setCountry] = useState<CountryCodeEnum>(CountryCodeEnum.US);

  const invalidateQuery = () => {
    queryClient.invalidateQueries({ queryKey: [EXEMPTIONS_STATE_KEY, orgId] });
  };

  const mutation = useMutation({
    mutationFn: (payload: any) => {
      return createExemption(orgId, payload);
    },
    onSuccess: data => {
      const file = formik.values.certificate_location;
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        uploadResellerCertificate(data.id, orgId, formData)
          .then(() => {
            invalidateQuery();
            handleSuccessNotification('Exemption has successfully imported');
          })
          .catch(error => {
            handleFailNotification(error);
          });
      } else {
        invalidateQuery();
        handleSuccessNotification('Exemption has successfully imported');
      }
      onClose();
    },
  });

  const calculateEndDate = (startDate: string) => {
    const endDate = addYears(parseISO(startDate), 3);
    return getFormattedDate(endDate, 'yyyy-MM-dd');
  };

  const handleStartDateChange = (date: string) => {
    const endDate: string = calculateEndDate(date);
    formik.setFieldValue('start_date', date);
    formik.setFieldValue('end_date', endDate);
  };

  const currentDate = getFormattedDate(new Date(), 'yyyy-MM-dd');
  const endDate = calculateEndDate(currentDate);

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    handleFileSelection(droppedFile);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleFileSelection = (selectedFile: File) => {
    if (selectedFile?.type !== 'application/pdf') {
      toaster.create({
        type: 'error',
        title: 'Invalid file type',
        description: 'Only PDF file is allowed.',
      });
      return;
    }
    setFileName(selectedFile?.name);
    formik.setFieldValue('certificate_location', selectedFile);
  };

  const handleCustomerSelect = (customer: any) => {
    if (customer != '') {
      formik.setFieldValue('customer_id', customer.id);
    } else {
      formik.setFieldValue('customer_id', '');
    }
  };

  const formik = useFormik({
    initialValues: {
      country_code: CountryCodeEnum.US,
      exemption_type: 'customer',
      customer_id: '',
      start_date: currentDate,
      end_date: endDate,
      FEIN: '',
      sales_tax_id: '',
      jurisdiction: '',
      certificate_location: '',
      reseller: false,
      status: 'ACTIVE',
      transaction_id: null,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      if (values.customer_id === '') {
        formik.setFieldError('customer_id', 'Customer is required');
        return;
      }
      mutation.mutate(values);
    },
  });

  const countriesCollection = useMemo(() => {
    return createListCollection({
      items: countries,
    });
  }, [countries]);
  const statesCollection = useMemo(() => {
    return createListCollection({
      items: stateOptions,
    });
  }, [stateOptions]);

  useEffect(() => {
    fetchStateOptions(country);
  }, [country, fetchStateOptions]);

  return (
    <DialogRoot
      placement="center"
      size={'lg'}
      open={isOpen}
      onOpenChange={({ open }) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Import Exemption</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <form noValidate>
            <Stack gap={'16px'}>
              <SearchCustomerInput onCustomerSelect={handleCustomerSelect} />
              {formik.errors.customer_id && formik.touched.customer_id && (
                <Text color={'#E53E3E'}>{formik.errors.customer_id}</Text>
              )}
              <HStack align={'baseline'}>
                <Field
                  label="Country"
                  invalid={!!(formik.errors.country_code && formik.touched.country_code)}
                  errorText={formik.errors.country_code}
                  required
                >
                  <SelectRoot
                    collection={countriesCollection}
                    value={country ? [country] : undefined}
                    onValueChange={({ value }) => {
                      setCountry(value[0] as CountryCodeEnum);
                      formik.setFieldValue('country_code', value[0]);
                      formik.setFieldValue('jurisdiction', '');
                    }}
                  >
                    <SelectTrigger>
                      <SelectValueText placeholder="Select" />
                    </SelectTrigger>
                    <SelectContent portalRef={contentRef} style={{ zIndex: 1500 }}>
                      {countriesCollection.items.map(item => (
                        <SelectItem item={item} key={item.value}>
                          {item.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </SelectRoot>
                </Field>
                <Field
                  label="State"
                  invalid={!!(formik.errors.jurisdiction && formik.touched.jurisdiction)}
                  errorText={formik.errors.jurisdiction}
                  required
                >
                  <SelectRoot
                    collection={statesCollection}
                    value={formik.values.jurisdiction ? [formik.values.jurisdiction] : undefined}
                    onValueChange={({ value }) => {
                      formik.setFieldValue('jurisdiction', value[0]);
                    }}
                  >
                    <SelectTrigger>
                      <SelectValueText placeholder="Select" />
                    </SelectTrigger>
                    <SelectContent portalRef={contentRef} style={{ zIndex: 1500 }}>
                      {statesCollection.items.map(item => (
                        <SelectItem item={item} key={item.value}>
                          {item.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </SelectRoot>
                </Field>
              </HStack>
              <HStack align={'baseline'}>
                <Field
                  label="Start Date"
                  invalid={!!(formik.errors.start_date && formik.touched.start_date)}
                  errorText={formik.errors.start_date}
                  required
                >
                  <DatePicker selected={formik.values.start_date} onChange={handleStartDateChange} />
                </Field>
                <Field
                  label="End Date"
                  invalid={!!(formik.errors.end_date && formik.touched.end_date)}
                  errorText={formik.errors.end_date}
                  required
                >
                  <DatePicker
                    selected={formik.values.end_date}
                    onChange={date => formik.setFieldValue('end_date', date)}
                  />
                </Field>
              </HStack>
              <HStack align={'baseline'}>
                <Field
                  label="FEIN/Tax ID"
                  invalid={!!(formik.errors.FEIN && formik.touched.FEIN)}
                  errorText={formik.errors.FEIN}
                  required
                >
                  <Input
                    id="FEIN"
                    type="string"
                    name="FEIN"
                    value={formik.values.FEIN}
                    onChange={formik.handleChange}
                  />
                </Field>
                <Field
                  label="Sales Tax ID"
                  invalid={!!(formik.errors.sales_tax_id && formik.touched.sales_tax_id)}
                  errorText={formik.errors.sales_tax_id}
                  required
                >
                  <Input
                    id="sales_tax_id"
                    type="string"
                    name="sales_tax_id"
                    value={formik.values.sales_tax_id}
                    onChange={formik.handleChange}
                  />
                </Field>
              </HStack>
              <Field required>
                <Switch
                  id="reseller"
                  name="reseller"
                  value={formik.values.reseller ? 'true' : 'false'}
                  checked={formik.values.reseller}
                  onCheckedChange={e => {
                    formik.setFieldValue('reseller', e.checked);
                    formik.setFieldValue('exemption_type', e.checked ? 'wholesale' : 'customer');
                  }}
                >
                  Is this customer a reseller?
                </Switch>
              </Field>
              <Text fontWeight={500}>Upload Certificate</Text>
              {formik.values.certificate_location || fileName ? (
                <HStack>
                  <Text>{fileName}</Text>
                  <IconButton
                    variant={'transparent-with-icon'}
                    aria-label="Remove file"
                    onClick={() => {
                      formik.setFieldValue('certificate_location', null);
                      setFileName('');
                    }}
                    pt={'10px'}
                    minWidth={'5px'}
                  >
                    <RemoveIcon />
                  </IconButton>
                </HStack>
              ) : (
                <Flex
                  onDrop={formik.values.reseller ? handleFileDrop : undefined}
                  onDragOver={formik.values.reseller ? handleDragOver : undefined}
                  height="78px"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  border="1px dashed #4285F4"
                  borderRadius="2px"
                  p={'16px'}
                >
                  <Text>
                    Drag and Drop your files here or{' '}
                    <Text as="label" color={'#4285F4'} textDecoration={'underline'} cursor="pointer">
                      Choose File
                      <Input
                        type="file"
                        id="file"
                        accept=".pdf"
                        display="none"
                        onChange={e => {
                          if (e.target.files && e.target.files.length > 0) {
                            handleFileSelection(e.target.files[0]);
                          } else {
                            toaster.create({
                              type: 'error',
                              title: 'Error',
                              description: 'Error selecting file ',
                            });
                          }
                        }}
                        onClick={(e: any) => {
                          e.target.value = '';
                        }}
                      />
                    </Text>
                  </Text>
                  <Text fontSize={'12px'} color={'#5F647B'}>
                    Max file size: 10MB. Supported type: PDF
                  </Text>
                </Flex>
              )}
            </Stack>
          </form>
        </DialogBody>
        <DialogFooter>
          <Flex gap={2}>
            <Button variant={'outline'} color={'secondary'} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant={'solid'}
              colorPalette="blue"
              width={'90px'}
              onClick={() => formik.handleSubmit()}
              loading={mutation.isPending}
            >
              Save
            </Button>
          </Flex>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};
