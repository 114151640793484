/* eslint-disable jsx-a11y/no-autofocus */
import { Input, PopoverContent, PopoverRoot, Table, useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { CUSTOMERS_STATE_KEY, getCustomers } from 'apis/customers';
import { CloseIcon, SearchIcon } from 'components/icons';
import { Field } from 'components/ui/field';
import { InputGroup } from 'components/ui/input-group';
import { useOrg } from 'hooks/useOrg';
import { useState } from 'react';
import { getHumanReadableString } from 'utils/enum-helpers';

type searchCustomerInputProps = {
  onCustomerSelect: (customer: any) => void;
};

export const SearchCustomerInput = ({ onCustomerSelect }: searchCustomerInputProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { orgId } = useOrg();
  const { open, onClose, onOpen } = useDisclosure();

  const { data } = useQuery({
    queryKey: [CUSTOMERS_STATE_KEY, orgId],
    queryFn: async () => {
      const { data } = await getCustomers(orgId);
      return data;
    },
  });

  const customerData = data?.items || [];

  const filteredCustomerData = customerData?.filter(
    ({ name, email, id }) =>
      name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      id?.toString().includes(searchTerm)
  );

  const handleFocusOutside = (e: Event) => {
    const target = e.target as HTMLElement;
    if (!target.closest('.search-container')) {
      onClose();
    }
  };

  return (
    <Field label="Customer" required>
      <PopoverRoot open={open} onFocusOutside={handleFocusOutside} autoFocus={false} defaultOpen={false}>
        <InputGroup
          startElement={<SearchIcon />}
          endElement={
            searchTerm && (
              <CloseIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSearchTerm('');
                  onCustomerSelect('');
                  onClose();
                }}
              />
            )
          }
          width={'640px'}
        >
          <Input
            placeholder={'Search'}
            value={searchTerm}
            onChange={e => {
              setSearchTerm(e.target.value);
              onOpen();
            }}
          />
        </InputGroup>
        <PopoverContent
          width="auto"
          maxH="250px"
          overflowY="auto"
          top="100%"
          left="0"
          right="0"
          position="absolute"
          zIndex={1000}
          boxShadow="lg"
        >
          <Table.Root>
            <Table.Header>
              <Table.Row style={{ borderBottom: 'none' }}>
                <Table.ColumnHeader>ID</Table.ColumnHeader>
                <Table.ColumnHeader>Customer Name</Table.ColumnHeader>
                <Table.ColumnHeader>Email</Table.ColumnHeader>
                <Table.ColumnHeader>Status</Table.ColumnHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {filteredCustomerData.map(customer => (
                <Table.Row
                  key={customer.id}
                  onClick={() => {
                    setSearchTerm(customer.email || customer.name || customer.id || '');
                    onCustomerSelect(customer);
                    onClose();
                  }}
                  style={{ borderBottom: 'none', cursor: 'pointer' }}
                  _hover={{ backgroundColor: '#EFEFF3' }}
                >
                  <Table.Cell height="36px" p={2} fontSize={'14px'}>
                    {customer.id}
                  </Table.Cell>
                  <Table.Cell height="36px" p={2} fontSize={'14px'}>
                    {customer.name}
                  </Table.Cell>
                  <Table.Cell height="36px" p={2} fontSize={'14px'}>
                    {customer.email}
                  </Table.Cell>
                  <Table.Cell height="36px" p={2} fontSize={'14px'}>
                    {getHumanReadableString(customer.status)}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table.Root>
        </PopoverContent>
      </PopoverRoot>
    </Field>
  );
};
