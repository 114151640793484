import { IconButton, useDisclosure } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CUSTOMERS_STATE_KEY } from 'apis/customers';
import { downloadResellerCertificate, updateExemption } from 'apis/exemption-apis';
import { EXEMPTIONS_STATE_KEY } from 'apis/exemptions';
import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from 'components/ui/menu';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { EditExemptionsForm } from 'pages/Exemptions/components/editExemptionForm';
import { MdMoreVert } from 'react-icons/md';

import { ArchiveExemptionForm } from './archiveExemptionModal';

type ExemptionActionProps = {
  exemption: any;
};
export const ExemptionAction = ({ exemption }: ExemptionActionProps) => {
  const { orgId } = useOrg();
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();
  const queryClient = useQueryClient();

  const { open: isArchiveModalOpen, onOpen: onArchiveModalOpen, onClose: onArchiveModalClose } = useDisclosure();

  const {
    open: isEditExemptionFormOpen,
    onOpen: onEditExemptionFormOpen,
    onClose: onEditExemptionFormClose,
  } = useDisclosure();

  const updateExemptionStatus = useMutation({
    mutationFn: (status: string) => {
      const payload = { ...exemption, status: status };
      return updateExemption(orgId, exemption.id, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CUSTOMERS_STATE_KEY, orgId] });
      queryClient.invalidateQueries({ queryKey: [EXEMPTIONS_STATE_KEY, orgId] });
      handleSuccessNotification('Exemption status updated successfully');
    },
  });

  const handleEditExemptionFormOpen = () => {
    onEditExemptionFormOpen();
  };

  const handleActivate = () => {
    updateExemptionStatus.mutate('ACTIVE');
  };

  const handleDeactivate = () => {
    updateExemptionStatus.mutate('DEACTIVATED');
  };

  const handleArchive = () => {
    onArchiveModalOpen();
  };

  const handleDownload = (attachment_id: any) => {
    handleSuccessNotification('File will be downloaded shortly');
    if (attachment_id) {
      downloadResellerCertificate(attachment_id, orgId)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'reseller_certificate.pdf');
          document.body.appendChild(link);
          link.click();
          handleSuccessNotification('File downloaded successfully');
        })
        .catch(error => {
          handleFailNotification(error);
        });
    }
  };

  const attachmentLength = exemption?.attachment?.length;

  return (
    <>
      <MenuRoot
        size="md"
        positioning={{ placement: 'top-start' }}
        onSelect={({ value }) => {
          switch (value) {
            case 'edit':
              handleEditExemptionFormOpen();
              break;
            case 'download':
              handleDownload(exemption?.attachment[attachmentLength - 1]?.id);
              break;
            case 'activate':
              handleActivate();
              break;
            case 'archive':
              handleArchive();
              break;
            case 'deactivate':
              handleDeactivate();
              break;
            default:
              break;
          }
        }}
      >
        <MenuTrigger aria-label="Options" asChild>
          <IconButton variant="transparent-with-icon">
            <MdMoreVert />
          </IconButton>
        </MenuTrigger>
        <MenuContent>
          <MenuItem value="edit">Edit</MenuItem>

          <MenuItem value="download" disabled={attachmentLength == 0}>
            Download Document
          </MenuItem>
          {exemption.status == 'DEACTIVATED' ? (
            <>
              <MenuItem value="activate">Activate</MenuItem>
              <MenuItem value="archive">Archive</MenuItem>
            </>
          ) : (
            <MenuItem value="deactivate">Deactivate</MenuItem>
          )}
        </MenuContent>
      </MenuRoot>

      {isEditExemptionFormOpen && (
        <EditExemptionsForm isOpen={isEditExemptionFormOpen} onClose={onEditExemptionFormClose} exemption={exemption} />
      )}

      {isArchiveModalOpen && (
        <ArchiveExemptionForm
          exemption={exemption}
          orgId={orgId}
          isOpen={isArchiveModalOpen}
          onClose={onArchiveModalClose}
        />
      )}
    </>
  );
};
