import { HStack, Stack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { EXEMPTIONS_STATE_KEY, getExemptions } from 'apis/exemptions';
import AppHeader from 'app/app-header';
import { ExemptionTrackingToolbar } from 'app-header-toolbar/exemption-tracking-toolbar';
import { PaginationButtons } from 'components/pagination/pagination-buttons';
import { useOrg } from 'hooks/useOrg';
import { DEFAULT_PAGE_SIZE, useTableFilters } from 'hooks/useTableFilters';

import { ExemptionTable } from './components/table';

const Exemptions = () => {
  const { orgId } = useOrg();
  const exemptionTableFilters = useTableFilters({
    order_by: ['end_date', 'FEIN', 'sales_tax_id', 'status'],
  });
  const { page, size, country_code, jurisdiction, start_date, end_date, status__in, order_by, setFilters } =
    exemptionTableFilters;
  const { isPending, data } = useQuery({
    queryKey: [
      EXEMPTIONS_STATE_KEY,
      orgId,
      page,
      size,
      country_code,
      jurisdiction,
      start_date,
      end_date,
      status__in,
      order_by,
    ],
    queryFn: async () => {
      const { data } = await getExemptions(orgId, {
        page,
        size,
        country_code,
        jurisdiction,
        start_date,
        end_date,
        status__in,
        order_by,
      });
      return data;
    },
  });

  const totalPages = data?.pages ?? 0;
  const exemptionData = data?.items || [];
  const isPaginationEnable = exemptionData.length > 0 && (data?.total ?? 0) > DEFAULT_PAGE_SIZE;
  const isFilterApplied = !!(start_date || end_date || status__in);

  return (
    <Stack>
      <HStack gap={1} justifyContent={'space-between'}>
        <AppHeader />
        <ExemptionTrackingToolbar tableFilters={exemptionTableFilters} />
      </HStack>

      <ExemptionTable
        data={exemptionData}
        tableFilters={exemptionTableFilters}
        isFilterApplied={isFilterApplied}
        isPending={isPending}
      />
      {isPaginationEnable && (
        <PaginationButtons currentPage={page} size={size} setFilters={setFilters} totalPages={totalPages} />
      )}
    </Stack>
  );
};

export default Exemptions;
