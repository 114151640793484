import { Box, useDisclosure } from '@chakra-ui/react';
import { Button } from 'components/ui/button';

import { ImportExemptionModal } from './import-exemption-form';

export const ImportExemptionButton = () => {
  const { open, onOpen, onClose } = useDisclosure();
  return (
    <Box>
      <Button variant={'solid'} colorPalette={'blue'} width={'100px'} onClick={onOpen}>
        Import
      </Button>
      {open && <ImportExemptionModal isOpen={open} onClose={onClose} />}
    </Box>
  );
};
