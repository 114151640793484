import { TableFilterPopover } from 'components/filter';
import { UseTableFiltersType } from 'hooks/useTableFilters';
import { useState } from 'react';

import { ExemptionFilter } from './exemption-filter';

type ExemptionPopoverProps = {
  tableFilters: UseTableFiltersType;
};
export const ExemptionPopover = ({ tableFilters }: ExemptionPopoverProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { start_date, end_date, status__in } = tableFilters;
  const hasFilter = !!(start_date || end_date || status__in);
  return (
    <TableFilterPopover hasFilter={hasFilter} open={open} onOpenChange={({ open }) => setOpen(open)}>
      <ExemptionFilter
        tableFilters={tableFilters}
        onClose={() => {
          setOpen(false);
        }}
      />
    </TableFilterPopover>
  );
};
