import { Flex, Text } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CUSTOMERS_STATE_KEY } from 'apis/customers';
import { updateExemption } from 'apis/exemption-apis';
import { EXEMPTIONS_STATE_KEY } from 'apis/exemptions';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { useHandleNotification } from 'hooks/useApiNotification';

type ArchiveExemptionFormProps = {
  isOpen: boolean;
  onClose: () => void;
  exemption: any;
  orgId: string;
};

export const ArchiveExemptionForm = ({ exemption, orgId, isOpen, onClose }: ArchiveExemptionFormProps) => {
  const queryClient = useQueryClient();
  const { handleSuccessNotification } = useHandleNotification();

  const archiveExemption = useMutation({
    mutationFn: (status: string) => {
      const payload = { ...exemption, status: status };
      return updateExemption(orgId, exemption.id, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CUSTOMERS_STATE_KEY, orgId] });
      queryClient.invalidateQueries({ queryKey: [EXEMPTIONS_STATE_KEY, orgId] });
      handleSuccessNotification('Exemption Archived Successfully');
      onClose();
    },
  });

  const handleConfirm = () => {
    archiveExemption.mutate('ARCHIVED');
  };

  return (
    <DialogRoot
      closeOnInteractOutside={false}
      size={'lg'}
      open={isOpen}
      onOpenChange={({ open }) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Are you sure you want to archive exemption?</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <Text>Once you archived this exemption it will no longer apply to this customer.</Text>
        </DialogBody>
        <DialogFooter>
          {' '}
          <Flex gap={2}>
            <Button variant={'outline'} color={'secondary'} onClick={onClose}>
              Cancel
            </Button>
            <Button variant={'danger'} width={'90px'} loading={archiveExemption.isPending} onClick={handleConfirm}>
              Confirm
            </Button>
          </Flex>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};
