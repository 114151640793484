import { Flex } from '@chakra-ui/react';
import { UseTableFiltersType } from 'hooks/useTableFilters';
import { ExemptionPopover } from 'pages/Exemptions/components/exemption-filter/exemption-popover';
import { ImportExemptionButton } from 'pages/Exemptions/components/import-exemption/import-exemption';

type ExemptionTrackingToolbarProps = {
  tableFilters: UseTableFiltersType;
};

export const ExemptionTrackingToolbar = ({ tableFilters }: ExemptionTrackingToolbarProps) => {
  return (
    <Flex gap={3} alignItems={'top'}>
      <ExemptionPopover tableFilters={tableFilters} />
      <ImportExemptionButton />
    </Flex>
  );
};
