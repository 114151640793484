import { Flex, Table, Text } from '@chakra-ui/react';
import { KDataTable } from 'components/table/data-table';
import { TableEmptyState } from 'components/table-empty-state/table-empty-state';
import { TableRowSkeleton } from 'components/ui/table-row-skeleton';
import { useSortableTable } from 'hooks/useSortableTable';
import { UseTableFiltersType } from 'hooks/useTableFilters';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomerInstance, ExemptionInstance, ExemptionStatus } from 'types/shared-types';
import { getCountryDisplay } from 'utils';
import { toDateShort } from 'utils/dates';

import { ExemptionAction } from './exemption-action';
import ExemptionBadge from './exemptionStateBadge';

const TABLE_HEAD = [
  'Country',
  'Jurisdiction',
  'Customer Name',
  'Start Date',
  'End Date',
  'FEIN/Tax ID',
  'Sales Tax ID',
  'Status',
];
const DEFAULT_VISIBLE_COLUMNS = TABLE_HEAD;
const SORT_HEADERS = ['End Date', 'FEIN/Tax ID', 'Sales Tax ID', 'Status'];
const ALL_SORTABLE_FIELDS = ['end_date', 'FEIN', 'sales_tax_id', 'status'];
const FIELD_MAPPING = {
  Country: 'country_code',
  Jurisdiction: 'jurisdiction',
  'Customer Name': 'customer_name',
  'Start Date': 'start_date',
  'End Date': 'end_date',
  'FEIN/Tax ID': 'FEIN',
  'Sales Tax ID': 'sales_tax_id',
  Status: 'status',
};

const COLUMN_WIDTHS = {
  Country: '100px',
  Jurisdiction: '100px',
  'Customer Name': '150px',
  'Start Date': '100px',
  'End Date': '100px',
  'FEIN/Tax ID': '100px',
  'Sales Tax ID': '100px',
  Status: '126px',
};

export const ExemptionTable = ({
  data,
  tableFilters,
  isFilterApplied,
  isPending,
}: {
  data: ExemptionInstance[];
  tableFilters: UseTableFiltersType;
  isFilterApplied: boolean;
  isPending: boolean;
}) => {
  const isDataEmpty = !data || data.length === 0;
  const { order_by, setFilters } = tableFilters;
  const [visibleColumns, setVisibleColumns] = useState<string[]>(DEFAULT_VISIBLE_COLUMNS);

  const handleToggleColumn = (columns: string[]) => {
    setVisibleColumns(columns);
  };

  const { handleSort, getSortIcon } = useSortableTable({
    initialOrderBy: order_by,
    allSortableFields: ALL_SORTABLE_FIELDS,
    fieldMapping: FIELD_MAPPING,
    setFilters,
  });

  if (isDataEmpty && !isPending) {
    return <TableEmptyState tableName="Exemptions" isFilterApplied={isFilterApplied} />;
  }

  return (
    <KDataTable
      headers={TABLE_HEAD}
      defaultVisibleColumns={visibleColumns}
      onVisibleColumnsChange={handleToggleColumn}
      showColumnFilter
      sortHeaders={SORT_HEADERS}
      getSortIcon={getSortIcon}
      handleSort={handleSort}
      columnWidths={COLUMN_WIDTHS}
      isPending={isPending}
    >
      {data?.map((row: any) => (
        <ExemptionTableRow
          key={row.id}
          exemption={row}
          visibleColumns={visibleColumns}
          customer={row.customer}
          {...row}
        />
      ))}
      {isPending && <TableRowSkeleton columns={TABLE_HEAD} />}
    </KDataTable>
  );
};

type ExemptionTableRowProps = ExemptionInstance & {
  visibleColumns: string[];
  exemption: ExemptionInstance;
  customer: CustomerInstance;
};

const ExemptionTableRow = ({
  country_code,
  jurisdiction,
  start_date,
  end_date,
  FEIN,
  sales_tax_id,
  status,
  exemption,
  visibleColumns,
  customer,
}: ExemptionTableRowProps) => {
  const navigate = useNavigate();

  const handleRowClick = () => {
    navigate(`/customers/${customer.id}`);
  };

  return (
    <Table.Row
      onClick={handleRowClick}
      _hover={{
        bg: '#EFEFF3',
        cursor: 'pointer',
      }}
    >
      {visibleColumns?.includes('Country') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{getCountryDisplay(country_code)}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns?.includes('Jurisdiction') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{jurisdiction}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns?.includes('Customer Name') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{customer.name}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns?.includes('Start Date') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{toDateShort(start_date)}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns?.includes('End Date') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{toDateShort(end_date)}</Text>
          </Flex>
        </Table.Cell>
      )}

      {visibleColumns?.includes('FEIN/Tax ID') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{FEIN}</Text>
          </Flex>
        </Table.Cell>
      )}

      {visibleColumns?.includes('Sales Tax ID') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{sales_tax_id}</Text>
          </Flex>
        </Table.Cell>
      )}

      {visibleColumns?.includes('Status') && (
        <Table.Cell>
          <Flex alignContent={'center'} justifyContent={'space-between'} minWidth="100%" flexWrap="nowrap">
            <ExemptionBadge status={status as ExemptionStatus} />
          </Flex>
        </Table.Cell>
      )}
      <Table.Cell
        width={'24px'}
        onClick={(event: React.MouseEvent<HTMLTableDataCellElement>) => event.stopPropagation()}
      >
        <ExemptionAction exemption={exemption} />
      </Table.Cell>
    </Table.Row>
  );
};
